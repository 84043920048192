/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import _, { filter } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { TreezorBeneficiaryTypes } from '@bebusinessfocus/compta-hub-core';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AppButton from 'components/AppButton';
import BankTransferFormik from 'components/CardComponents/Treezor/BankTransferForm/BankTransferFormik';
import style from 'assets/jss/components/appDialog';
import { useCreateDialog } from 'hooks/dialogHooks';
import AppDialog from 'components/AppDialog';
import { EXPENSE_STATE, EXPENSE_TYPE } from 'constants/expense';
import { useScanOcr } from 'hooks/scanOcr/useScanOcr';
import useQueryParams from 'hooks/useQueryParams';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  fetchExpense,
  updateInvoiceExpense,
} from '../../../actions/ExpensesActions';
import { useRouter } from '../../../hooks/routerHooks';
import { useThunkDispatch } from '../../../hooks/generalHooks';
import PaymentTypes from '../../../helpers/PaymentTypes';
import Layout from '../../../components/Layout';
import PurchaseFormReadonly from '../../../components/Expenses/FullForm';
import Header from '../../../components/Header';
import CircularProgressCentered from '../../../components/CircularProgressCentered';
import { authInstance as http } from '../../../helpers/axiosInterceptor';

import Button from './Button';
import PurchaseForm from '../PurchaseForm/Form/PurchaseForm';
import { handleValidatePurchase } from './action';
import validationSchema from '../PurchaseForm/validation';
import { ProviderFormDialog } from '../PurchaseForm/Dialogs';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wapperCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  marginLeft: {
    marginLeft: '20px',
  },
  wapperSpaceEvenly: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

const PurchaseViewContainer = (props) => {
  const { id: expenseId } = props.match.params;
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [totalPayoutAmount, setTotalPayoutAmount] = useState(0);
  const [alertHandler, setAlertHandler] = useState({
    isOpen: false,
    message: '',
    status: 'error',
  });
  const [showApprovalDialog, setApprovalDialogVisibility] = useState(false);
  const {
    showDialog: showNewProviderDialog,
    toggleDialog: toggleNewProviderDialog,
    setNewItem: setNewProvider,
  } = useCreateDialog();

  const dispatch = useThunkDispatch();
  function toggleRefreshing() {
    setRefreshing((prev) => !prev);
  }
  const fetchExpenseCb = useCallback(
    () => dispatch(fetchExpense(expenseId, setError)),
    [dispatch]
  );

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setInitialValues({
        id: expenseId,
        error,
        due_date: format(new Date()),
        expense_nb: '',
        state: '',
        currency: '',
        validated_at: format(new Date()),
        category_id: '',
        categoryDisplay: '',
        date: format(new Date()),
        filename: '',
        invoice_nb: '',
        payment_type: '',
        payment_typeDisplay: '',
        description: '',
        is_intern: false,
        is_billable: false,
        provider: '',
        providerDisplay: '',
        providerSiret: '',
        travel_from: '',
        travel_to: '',
        reference: '',
        duePayableAmount: 0,
        buyerOrderReferencedDocument: '',
        internal_activity: '',
        internal_activityDisplay: '',
        client_id: '',
        clientDisplay: '',
        project_id: '',
        projectDisplay: '',
        amount0: 0,
        amount1: 0,
        amount2: 0,
        amount3: 0,
        total0: 0,
        total1: 0,
        total2: 0,
        total3: 0,
        vat0: 0,
        vat1: 0,
        vat2: 0,
        vat3: 0,
        vatrate0: 0,
        vatrate1: 0,
        vatrate2: 0,
        vatrate3: 0,
        numberOfVatLine: 2,
        billNumber: '',
        orderNumber: '',
        amounts: [],
      });
    }
  }, [error]);

  useEffect(() => {
    if (refreshing) {
      setLoading(true);
    }
  }, [refreshing]);

  const expenses = useSelector((state) => state.expenses);
  const clients = useSelector((state) => state.clients.data);
  const projects = useSelector((state) => state.clientProjects.projects);
  const providers = useSelector((state) => state.providers);
  const activities = useSelector((state) => state.activities);
  const categories = useSelector((state) => state.categories.data);
  const companyAccounts = useSelector((state) =>
    state.loggedUserCompany.companies ? state.loggedUserCompany.companies : {}
  );
  const { company } = useSelector((state) => state.loggedUserCompany);
  const users = useSelector((state) => (state.users ? state.users : {}));
  const paymentTypes = filter(PaymentTypes, (type) => type._id !== 'personal');

  // We need to RenderForm only if initialValues is not null
  useEffect(() => {
    if (initialValues !== null) {
      setLoading(false);
    }
  }, [initialValues]);

  // Refetch Expenses if it isn't in redux state already (in case the user is refreshing page)
  useEffect(() => {
    if (!expenses || !expenses[expenseId]) {
      toggleRefreshing();
      fetchExpenseCb();
    }
  }, []);

  // Populate form with values from id and expenses array from redux state each time expenses from redux state is changing value
  useEffect(() => {
    if (expenses && expenses[expenseId]) {
      const purchase = expenses[expenseId];

      const { transfers } = purchase;

      if (transfers && transfers.length > 0) {
        // eslint-disable-next-line
        transfers.map((transfer) => {
          const { payoutId } = transfer;

          if (payoutId && typeof payoutId === 'object') {
            const { amount } = payoutId;

            setTotalPayoutAmount(totalPayoutAmount + Number(amount));
          }
        });
      }

      let project;
      if (
        projects[purchase.client_id] &&
        projects[purchase.client_id].length > 0
      ) {
        const projectFiltered = projects[purchase.client_id].filter(
          (el) => el._id === purchase.project_id
        );
        if (projectFiltered && projectFiltered.length > 0) {
          project = projectFiltered[0].description;
        }
      }
      let provider;
      let providerSiret;
      if (providers && providers[purchase.provider]) {
        provider = providers[purchase.provider].name;
        providerSiret = providers[purchase.provider].siret;
      }
      let payment_type;
      const tmpPaymentArray = PaymentTypes.filter(
        (el) => el._id === purchase.payment_type
      );
      if (tmpPaymentArray && tmpPaymentArray.length > 0) {
        payment_type = tmpPaymentArray[0].display;
      }
      let client;
      if (clients && clients[purchase.client_id]) {
        client = clients[purchase.client_id].name;
      }
      let activitie;
      if (activities && activities[purchase.internal_activity]) {
        activitie = activities[purchase.internal_activity].display;
      }
      let category;
      if (categories && categories[purchase.category_id]) {
        category = categories[purchase.category_id].description;
      }
      const amountsData = purchase?.amounts.map((item) => {
        return {
          ...item,
          categoryDisplay:
            categories[item.category_id] &&
            categories[item.category_id]?.display,
          sub_categoryDisplay:
            categories[item.sub_category_id] &&
            categories[item.sub_category_id]?.display,
        };
      });
      const valuesOfForm = {
        id: expenseId,
        orderNumber: purchase.orderNumber,
        due_date:
          purchase.due_date ||
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        expense_nb: purchase.expense_nb,
        state: purchase.state,
        displayState: purchase.displayState,
        currency: purchase.currency,
        validated_at: purchase.validated_at,
        category_id: purchase.category_id,
        categoryDisplay: category !== undefined ? category : '',
        date:
          purchase.date || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        filename: purchase.filename,
        invoice_nb: purchase.invoice_nb,
        payment_type: purchase.payment_type,
        payment_typeDisplay: payment_type !== undefined ? payment_type : '',
        description: purchase.description,
        is_intern: purchase.is_intern,
        is_billable: purchase.is_billable,
        provider: purchase.provider,
        providerDisplay: provider !== undefined ? provider : '',
        providerSiret: providerSiret || '',
        hasProvider: true,
        travel_from: purchase.travel ? purchase.travel.from : '',
        travel_to: purchase.travel ? purchase.travel.to : '',
        reference: purchase.reference,
        billNumber: purchase.billNumber,
        duePayableAmount: purchase.duePayableAmount,
        taxBasisTotalAmount: purchase.taxBasisTotalAmount,
        taxTotalAmount: purchase.taxTotalAmount,
        grandTotalAmount: purchase.grandTotalAmount,
        buyerOrderReferencedDocument: purchase.buyerOrderReferencedDocument,
        internal_activity: purchase.internal_activity,
        internal_activityDisplay: activitie !== undefined ? activitie : '',
        client_id: purchase.client_id,
        clientDisplay: client !== undefined ? client : '',
        project_id: purchase.project_id,
        projectDisplay: project !== undefined ? project : '',
        amounts: amountsData,
        amount0: 0,
        amount1: 0,
        amount2: 0,
        amount3: 0,
        total0: 0,
        total1: 0,
        total2: 0,
        total3: 0,
        vat0: 0,
        vat1: 0,
        vat2: 0,
        vat3: 0,
        vatrate0: 0,
        vatrate1: 0,
        vatrate2: 0,
        vatrate3: 0,
        numberOfVatLine: purchase.amounts ? purchase.amounts.length : 1,
      };
      const { amounts } = purchase;
      if (amounts) {
        for (let index = 0; index < amounts.length; index += 1) {
          const el = amounts[index];
          valuesOfForm[`amount${index}`] = el.amount;
          valuesOfForm[`total${index}`] = el.total;
          valuesOfForm[`vat${index}`] = el.vat;
          valuesOfForm[`vatrate${index}`] = el.vat_rate;
        }
      }
      setInitialValues(valuesOfForm);
    }
  }, [expenses]);

  const updatePurchase = useCallback(
    (id, data) => dispatch(updateInvoiceExpense(id, data)),
    [dispatch]
  );

  // this onSubmit only handles pay action
  const onSubmit = async (values, formikAction) => {
    setLoading(true);
    // TODO: this is ugly....
    // TODO: action or at least service and not a direct POST from here:

    let data;
    let message;

    if (values.action === 'validate') {
      setApprovalDialogVisibility(false);
      const res = await handleValidatePurchase(values, formikAction, company);
      data = res.data;
      message = res.message;
    } else {
      const transactionIso = {
        ...values.transaction,
        date: new Date(values.transaction.date).toISOString(),
      };
      const res = await http.post(
        `${process.env.REACT_APP_EXPENSES_URL}/expenses/${values.id}/paid`,
        {
          transaction: transactionIso,
        }
      );
      data = res.data;
      message = res.message;
    }

    if (message && message.length > 0) {
      setLoading(false);
      setError(message);
    } else {
      updatePurchase(values.id, data);
      setLoading(false);
    }
  };

  // Pay dialog
  const [payDialog, setPayDialog] = useState(false);

  const handleOpenPayDialog = () => {
    setPayDialog(true);
  };

  // eslint-disable-next-line
  const handleClosePayDialog = (event, reason) => {
    if (reason !== 'escapeKeyDown') {
      setPayDialog(false);
      setAlertHandler({
        isOpen: false,
        message: '',
        status: '',
      });
    }
  };

  const renderPayDialog = () => {
    const source = {
      id: expenseId,
      type: 'expense',
      filename: expenses[expenseId].filename,
    };

    const providerOId =
      expenses[expenseId] && expenses[expenseId].provider
        ? expenses[expenseId].provider
        : null;

    const prefill = {
      beneficiaryType: expenses[expenseId] ? 'provider' : null,
      beneficiary: providerOId ? providers[providerOId] : null,
      amount: expenses[expenseId].grandTotalAmount - totalPayoutAmount,
    };

    return (
      <Dialog
        maxWidth="md"
        open={payDialog}
        onClose={handleClosePayDialog}
        aria-labelledby="pay-dialog-title"
      >
        <DialogTitle id="pay-dialog-title">
          <Typography variant="h6">{t(`pay_a_purchase`)}</Typography>
          {handleClosePayDialog ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClosePayDialog}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          {alertHandler.isOpen && (
            <Alert
              severity={alertHandler.status}
              style={{
                margin: '0 5px 10px -10px',
              }}
            >
              {alertHandler.message}
            </Alert>
          )}
          <BankTransferFormik
            setAlertHandler={setAlertHandler}
            providers={[
              ..._.values(providers).map((provider) => ({
                ...provider,
                id: provider._id,
              })),
            ]}
            clients={[
              ..._.values(clients).map((client) => ({
                ...client,
                id: client._id,
              })),
            ]}
            users={[
              ..._.values(users).map((user) => ({
                ...user,
                id: user._id,
              })),
            ]}
            companyAccounts={[
              ...companyAccounts.map((account) => ({
                ...account,
                id: account._id,
              })),
            ]}
            beneficiaryTypes={TreezorBeneficiaryTypes}
            source={source}
            prefill={prefill}
            setPayDialog={setPayDialog}
            expenseId={expenseId}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const RenderApproveActionDialog = () => {
    const { setFieldValue, handleSubmit } = useFormikContext();
    if (showApprovalDialog) {
      return (
        <AppDialog
          title={t('sure')}
          closeDialog={() => setApprovalDialogVisibility(false)}
          sm
          iconClose
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          footer
          onConfirm={() => {
            setFieldValue('action', 'validate');
            setTimeout(() => {
              handleSubmit();
            }, 100);
          }}
          contentText={t('invoice.confirm.validate')}
          color="secondary"
        />
      );
    }
    return null;
  };

  const renderBody = (setFieldValue, values) => {
    const history = useHistory();
    const { cacheId } = useQueryParams();

    const { onOcr, ocrError, asyncScanOcr, getValuesFromCache } = useScanOcr({
      setFieldValue,
      values,
      toggleNewProviderDialog,
      type: EXPENSE_TYPE.PURCHASE,
    });

    const handleConfirmOcr = async (isConfirm) => {
      if (!isConfirm) {
        await onOcr(values?.filename);
        return;
      }
      await asyncScanOcr(EXPENSE_TYPE.PURCHASE);
      history.push('/purchases/list');
    };

    useEffect(() => {
      if (!values?.provider) {
        if (cacheId) getValuesFromCache(cacheId);
        else toggleNewProviderDialog();
      }
    }, [cacheId]);

    return (
      <React.Fragment>
        {renderPayDialog()}
        <RenderApproveActionDialog />
        <ProviderFormDialog
          showNewProviderDialog={showNewProviderDialog}
          toggleNewProviderDialog={toggleNewProviderDialog}
          setNewProvider={setNewProvider}
          stylePaper={style.positionRight}
        />
        <ConfirmDialog
          toggleDialog={setOpenConfirm}
          confirmMessage={t('expenses.ocr_confirm.description')}
          title={t('expenses.ocr_confirm.title')}
          isShowDialog={openConfirm}
          confirmText={t('expenses.ocr_confirm.wait_btn')}
          cancelText={t('expenses.ocr_confirm.return_btn')}
          // type={confirmType}
          onConfirm={handleConfirmOcr}
          isCancelConfirm={true}
        />
        {initialValues?.state === EXPENSE_STATE.DRAFT ? (
          <PurchaseForm
            defaultTaxes={[]}
            isPurchase={true}
            paymentTypes={paymentTypes}
            clientProjects={projects}
            clients={clients}
            onOcr={onOcr}
            ocrError={ocrError}
            setOpenConfirmScan={setOpenConfirm}
            toggleNewProviderDialog={toggleNewProviderDialog}
          />
        ) : (
          <PurchaseFormReadonly />
        )}

        <div className={classes.wapperCenter}>
          {(totalPayoutAmount < expenses[expenseId].total ||
            totalPayoutAmount < expenses[expenseId].grandTotalAmount) &&
            expenses[expenseId].state === EXPENSE_STATE.VALIDATED && (
              <AppButton
                type="button"
                text={t('purchases.purchase.pay')}
                onClick={handleOpenPayDialog}
              />
            )}
          <div className={classes.marginLeft}>
            <Button />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const { history } = useRouter();
  const goBack = () => {
    history.goBack();
  };

  // there are two returns because if initialValues is null we can't use <Button/> and <PurchaseFormReadonly />
  if (initialValues !== null && !loading) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ validateForm, values, setTouched, setFieldValue }) => (
          <Layout
            header={
              <Header
                name={t(`expenses.purchases.title`)}
                goBack={goBack}
                spaceBetween
              />
            }
            sidebarLeft={true}
            sidebarTop={
              loading ? null : (
                <div className={classes.wapperSpaceEvenly}>
                  {(totalPayoutAmount < expenses[expenseId].total ||
                    totalPayoutAmount < expenses[expenseId].grandTotalAmount) &&
                    expenses[expenseId].state === EXPENSE_STATE.VALIDATED && (
                      <AppButton
                        type="button"
                        text={t('purchases.purchase.pay')}
                        onClick={handleOpenPayDialog}
                      />
                    )}
                  {expenses[expenseId].state === EXPENSE_STATE.DRAFT && (
                    <AppButton
                      type="button"
                      text={t('purchases.purchase.validate')}
                      onClick={() => {
                        validateForm().then((errors) => {
                          if (!Object.keys(errors).length) {
                            setApprovalDialogVisibility(true);
                          } else {
                            setTouched(
                              Object.keys(values).reduce((acc, field) => {
                                acc[field] = true;
                                return acc;
                              }, {})
                            );
                          }
                        });
                      }}
                    />
                  )}
                  {!location.state?.notShowReconclilier && <Button />}
                </div>
              )
            }
            showUserCard={true}
            body={
              loading ? (
                <CircularProgressCentered />
              ) : (
                renderBody(setFieldValue, values)
              )
            }
          />
        )}
      </Formik>
    );
  }
  return (
    <Layout
      header={
        <Header
          name={t(`expenses.purchases.title`)}
          goBack={goBack}
          spaceBetween
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={<CircularProgressCentered />}
    />
  );
};

PurchaseViewContainer.propTypes = {
  expenseId: PropTypes.string,
  match: PropTypes.object,
};

export default PurchaseViewContainer;
